@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply mx-auto max-w-9xl overflow-x-hidden font-articulat text-zve-black;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }

  .container {
    @apply 3xl:!max-w-[1536px];
  }
}

@layer components {
  .btn--rounded {
    @apply inline-flex items-center rounded-full py-3 px-4 text-sm font-semibold uppercase transition-all md:py-5 md:px-6 md:text-base;
  }

  .btn--rounded--sm {
    @apply inline-flex items-center rounded-full py-2 px-4 text-sm font-semibold uppercase transition-all md:text-base;
  }

  .btn {
    @apply inline-flex items-center gap-3 rounded-md py-3 px-5 font-semibold uppercase transition-all;
  }

  .btn--primary {
    @apply bg-zve-furret uppercase text-zve-delphox hover:bg-zve-shiny-bayleef;
  }

  .btn--corphish {
    @apply bg-zve-corphish uppercase text-white hover:bg-zve-galarian-corsola hover:text-zve-corphish;
  }

  .btn--light {
    @apply bg-white uppercase text-zve-corphish hover:bg-zve-galarian-corsola;
  }

  .btn--primary-outline {
    @apply border-2 border-zve-corphish uppercase text-zve-corphish hover:bg-zve-corphish hover:text-white;
  }

  .btn--primary-bare {
    @apply border-2 border-transparent uppercase text-zve-corphish hover:border-zve-corphish hover:bg-zve-corphish hover:text-white;
  }

  .btn--outline {
    @apply border-2 border-white bg-transparent uppercase hover:bg-white hover:text-zve-mightyena;
  }

  .btn--facebook {
    @apply bg-[#3C5A99] uppercase text-white hover:bg-[#263a62];
  }
}

@layer utilities {
  .content-9xl {
    @apply children-headings:text-4xl md:children-headings:text-6xl lg:children-headings:text-7xl xl:children-headings:text-8xl 2xl:children-headings:text-9xl;
  }

  .content-7xl {
    @apply children-headings:text-4xl sm:children-headings:text-5xl md:children-headings:text-6xl lg:children-headings:text-7xl;
  }

  .content-6xl {
    @apply children-headings:text-3xl sm:children-headings:text-4xl md:children-headings:text-5xl lg:children-headings:text-6xl;
  }

  .content-5xl {
    @apply children-headings:text-5xl;
  }

  .content-4xl {
    @apply children-headings:text-3xl lg:children-headings:text-4xl;
  }

  .content-2xl {
    @apply children-headings:text-2xl;
  }

  .content-border {
    @apply border-t-2 border-zve-black;
  }

  .content-border.content-7xl {
    @apply sm:pt-0 pt-3;
  }

  .section,
  .alt-section {
    @apply py-5 md:py-10 lg:py-20 xl:py-24;
  }

  .sep-section {
    @apply py-8 md:py-10 lg:py-20 xl:py-24;
  }

  .section + .section {
    @apply !pt-0;
  }

  .text-shadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5;
}

.parsecontent h2 {
  @apply mb-3 text-5xl xl:text-7xl;
}

.parsecontent h3 {
  @apply mb-3 text-5xl tracking-wide;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-extrabold;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent p strong {
  @apply mb-3 block;
}

.parsecontent p {
  @apply tracking-wide;
}

.parsecontent a {
  @apply font-normal;
}

.parsecontent a:hover {
  @apply underline;
}

.parsecontent ul {
  @apply pl-5;
}

.parsecontent ol {
  @apply pl-5;
}

.parsecontent ul > li {
  @apply relative list-none overflow-visible py-3 pl-6 before:absolute before:-left-[20px] before:top-[14px] before:h-[34px] before:w-[32px] before:bg-[url('/img/arrow-right.svg')] before:bg-no-repeat;
}

.parsecontent ol > li {
  @apply relative list-none overflow-visible py-3 pl-6 before:absolute before:-left-[20px] before:top-[16px] before:h-[34px] before:w-[32px] before:bg-[url('/img/arrow-right.svg')] before:bg-no-repeat;
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-contact > form {
  @apply h-full;
}

.form-duuf-group {
  @apply mb-5;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply relative h-12 w-full rounded-md border border-zve-black px-4 text-slate-800 !shadow-none !ring-0;
}

.form-duuf-textarea {
  @apply min-h-[9rem] pt-3;
}

.form-duuf-file-input {
  @apply text-slate-500 file:mr-4 file:rounded-full file:border-none file:bg-slate-800 file:py-2 file:px-4 file:text-white file:hover:bg-blue-500 file:hover:transition-all;
}

.form-duuf-checkbox {
  @apply mr-4 h-7 w-7 rounded-md border-none bg-slate-100 text-slate-500 focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-group-section label {
  @apply pt-5 text-2xl text-zve-corphish;
}

.form-duuf-select__control {
  @apply h-12 !border-none !bg-slate-100 !shadow-none;
}

.form-duuf-select__menu {
  @apply rounded-lg;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-lg transition-all;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-slate-500 !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-slate-100;
}

.form-duuf-select__option--is-selected {
  @apply !bg-slate-800;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[12px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[12px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply absolute right-0 top-0 text-sm text-red-500;
}

.form-duuf-label {
  @apply mb-2 block px-3 text-base font-bold;
}

.form-duuf-submit-disabled {
  @apply opacity-50 hover:bg-blue-500;
}

.form-duuf-submit > button {
  @apply btn btn--primary;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}
/* ----------END FORM---------- */

/* ----------START SLIDER---------- */
.swiper-button-disabled {
  @apply opacity-20;
}

/* ----------END SLIDER---------- */

#nprogress .bar {
  background: #f36420 !important;
  height: 3px !important;
  shadow: none !important;
}

#nprogress .spinner-icon {
  border-top-color: #f36420 !important;
  border-left-color: #f36420 !important;
}

#nprogress .peg {
  box-shadow:
    0 0 10px #f36420,
    0 0 5px #f36420 !important;
}
